import React from 'react'
import './Footer.css'
import { BsGithub, BsLinkedin } from 'react-icons/bs'
import { FaHackerrank } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">Morris Simons</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href='https://www.linkedin.com/in/morris-simons-a8b519120/' target='_blank' rel="noreferrer"><BsLinkedin /></a>
        <a href='https://github.com/MorrisSimons' target='_blank' rel="noreferrer"><BsGithub /></a>
        <a href='https://www.hackerrank.com/morris_simons01' target='_blank' rel="noreferrer"><FaHackerrank /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Morris Simons. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer