import React from 'react'
import "./Portfolio.css"
import IMG1 from "../../assets/portfolio1.webp"
import IMG2 from "../../assets/portfolio2.jpg"
import IMG3 from "../../assets/pass.jfif"
import IMG4 from "../../assets/FbAd1.jpg"
import IMG5 from "../../assets/Telefon_Ny.png"
import IMG6 from "../../assets/portfolio6.jpg"
import IMG7 from "../../assets/portfolio7.png"
import IMG8 from "../../assets/portfolio8.webp"
import IMG9 from "../../assets/portfolio9.png"
import IMG10 from "../../assets/portfolio10.png"
import IMG11 from "../../assets/portfolio11.jpg"

const data = [

  {
    id: 11,
    image: IMG11,
    title: "Genetic Algorithm for solving a maze",
    website: "https://github.com/MorrisSimons/SCH_Applied_ai_assignment_2",
    demo: "",
    desription: "Built a genetic algorithm that solves a maze, the maze is also solved through DFS and BFS in another repo on my github"
  },

  {
    id: 10,
    image: IMG10,
    title: "Machine Learning Projects from a ML course",
    website: "https://github.com/MorrisSimons/SCH_DV2599-Maskinlarning",
    demo: "",
    desription: "This git repo contains a collection of simple machine learning projects that I have worked on in the course DV2599"
  },
  {
    id: 9,
    image: IMG9,
    title: "Realestate portfolio Mapping",
    website: "",
    demo: "https://www.google.com/maps/d/edit?mid=19oC-eF90rvOs3p1cJPKI7RV-DtAv7Uc&usp=sharing",
    desription: "Built program that maps realestate companies portfolio to visulize them in another way. The code is private for now"
  },
  {
    id: 8,
    image: IMG8,
    title: "Visualizing Swedens Learning Landscape",
    website: "https://github.com/MorrisSimons/SCH_Visualizing_Swedens_Learning_Landscape",
    demo: "",
    desription: "Visualisation of the highest education in Sweden between women and men as well as foreign and domestic born students",
  },
  {
    id: 7,
    image: IMG7,
    title: "Award winning Figma Design of Canvas",
    website: "",
    demo: "https://www.figma.com/proto/dzZzAScZ7MNNQZADaTGgwD/Usability-and-Interaction-Design---Group-4?node-id=96-3721&scaling=scale-down&page-id=55%3A809&starting-point-node-id=96%3A3721&show-proto-sidebar=1",
    desription: "Developed with a couple of students this figma design that is now used to teach new students about design at BTH",
  },
  {
    id: 6,
    image: IMG1,
    title: "Ai captcha solver useing CNN",
    website: "https://github.com/MorrisSimons/Free_ai_captcha_solver_model",
    demo: "https://clipchamp.com/watch/eFzwJ3F6Q3t",
    desription: "Captcha solver for disorted text images for SAT in Mexico built with Pytorch",
  },
  {
    id: 5,
    image: IMG4,
    title: "Citas Rapidas",
    website: "https://citas-rapidas.com.mx/",
    demo: "",
    desription: "Booking agency for timeslots on SAT in Mexico, 300+ Customers"
  },

  {
    id: 4,
    image: IMG3,
    title: "Passtider-Snabbt.se",
    website: "https://www.trustpilot.com/review/passtider-snabbt.se?languages=all&sort=recency",
    demo: "",
    desription: "Swedish Booking agency for passports and id",
  },
  {
    id: 3,
    image: IMG2,
    title: "Blocket license plate Recognition",
    website: "",
    demo: "https://clipchamp.com/watch/AmfrTpRCk8k",
    desription: "License plate recognition used for valuation of cars on blocket.se, Ai model built with Tensorflow",
  },
  {
    id: 2,
    image: IMG5,
    title: "Bokaförarprov.se",
    webiste: "https://bokaforarprov.se/",
    demo: "",
    desription: "Booking agency for your driving test in sweden"
  },
  {
    id: 1,
    image: IMG6,
    title: "SL Planknings App",
    website: "",
    demo: "",
    desription: "Made an app for free travel on sweedish public transport, no demo avalible",
  }
]



const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My recent work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {
          data.map(({ id, image, title, website, demo, desription }) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <p>{desription}.</p>
                <div className="portfolio__item-cta">
                  {website && <a href={website} className="btn btn-primary">Read More</a>}
                  {demo && <a href={demo} className="btn btn-secondary">Demo</a>}
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio
