import React from 'react'
import './Experience.css'
import { AiFillHtml5 } from "react-icons/ai"
import { SiDocker } from "react-icons/si"
import { FaReact } from "react-icons/fa"
import { SiCsswizardry } from "react-icons/si"
import { SiGoogleoptimize } from "react-icons/si"
import { SiPostman } from "react-icons/si"
import { SiJavascript } from "react-icons/si"
import { SiGoogleanalytics } from "react-icons/si"
import { SiPython } from "react-icons/si"
import { FaMailBulk } from "react-icons/fa"
import { SiChatbot } from "react-icons/si"
import { DiLinux } from "react-icons/di"
import { GrMysql } from "react-icons/gr"
import { DiPhotoshop } from "react-icons/di"
import { SiPhpmyadmin } from "react-icons/si"
import { SiSelenium } from "react-icons/si"
import { SiScikitlearn } from "react-icons/si"
import { SiScipy } from "react-icons/si"
import { SiJupyter } from "react-icons/si"
import { SiPytorch } from "react-icons/si"
import { SiTensorflow } from "react-icons/si"
import { SiKeras } from "react-icons/si"
import { SiStripe } from "react-icons/si"
import { SiWoocommerce } from 'react-icons/si'
import { SiAnaconda } from 'react-icons/si'
import { SiAndroidstudio } from 'react-icons/si'
import { SiBootstrap } from 'react-icons/si'
import { SiCplusplus } from 'react-icons/si'
import { SiFlask } from 'react-icons/si'
import { SiPhp } from 'react-icons/si'
import { SiDataiku } from 'react-icons/si'


const Experience = () => {
  return (
    <section id="experience">
      <h5>Quick overview of skills</h5>
      <h2>Experience</h2>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className='experience__content'>

            <article className="experience__details">
              <FaReact className='experience__details-icon' />
              <div>
                <h4>React</h4>
                <small className='text-ligth'>Experienced</small>
              </div>

            </article>
            <article className="experience__details">
              <SiCsswizardry className='experience__details-icon' />
              <div>
                <h4>CSS</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiGoogleoptimize className='experience__details-icon' />
              <div>
                <h4>Google optimize</h4>
                <small className='text-ligth'>Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <SiWoocommerce className='experience__details-icon' />
              <div>
                <h4>Woocommerce</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiChatbot className='experience__details-icon' />
              <div>
                <h4>Chatbots</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiJavascript className='experience__details-icon' />
              <div>
                <h4>Java Script</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <DiPhotoshop className='experience__details-icon' />
              <div>
                <h4>Photoshop</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>



          </div>
        </div>
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className='experience__content'>
            <article className="experience__details">
              <SiDocker className='experience__details-icon' />
              <div>
                <h4>Docker</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiPython className='experience__details-icon' />
              <div>
                <h4>Python</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiPostman className='experience__details-icon' />
              <div>
                <h4>Postman</h4>
                <small className='text-ligth'>Experienced</small>
              </div>

            </article>
            <article className="experience__details">
              <FaMailBulk className='experience__details-icon' />
              <div>
                <h4>Mail services</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiGoogleanalytics className='experience__details-icon' />
              <div>
                <h4>Google analytics</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiSelenium className='experience__details-icon' />
              <div>
                <h4>Selenium & BS4</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>

            <article className="experience__details">
              <DiLinux className='experience__details-icon' />
              <div>
                <h4>Linux</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiFlask className='experience__details-icon' />
              <div>
                <h4>Flask</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiAnaconda className='experience__details-icon' />
              <div>
                <h4>Anaconda</h4>
                <small className='text-ligth'>Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <SiPytorch className='experience__details-icon' />
              <div>
                <h4>Pytorch</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiScikitlearn className='experience__details-icon' />
              <div>
                <h4>Scikitlearn</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiTensorflow className='experience__details-icon' />
              <div>
                <h4>Tensorflow </h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiJupyter className='experience__details-icon' />
              <div>
                <h4>Jupyter</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <SiDataiku className='experience__details-icon' />
              <div>
                <h4>Dataiku</h4>
                <small className='text-ligth'>Experienced</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience