import React from 'react'
import "./Nav.css"
import { AiOutlineHome } from "react-icons/ai"
import { AiOutlineUser } from "react-icons/ai"
import { AiOutlineGitlab } from "react-icons/ai"
import { AiOutlineCluster } from "react-icons/ai"
import { AiOutlinePhone } from "react-icons/ai"
import { useState } from "react"
const Nav = () => {
  const [activeNav, setActiveNav] = useState("#")
  return (
    <nav>
      <a href="#" onClick={() => setActiveNav("#")} className={activeNav === "#" ? "active" : "nav__icon"}><AiOutlineHome /></a>
      <a href="#about" onClick={() => setActiveNav("#about")} className={activeNav === "#about" ? "active" : "nav__icon"}><AiOutlineUser /></a>
      <a href="#portfolio" onClick={() => setActiveNav("#portfolio")} className={activeNav === "#portfolio" ? "active" : "nav__icon"}><AiOutlineGitlab /></a>
    </nav>
  )
}

export default Nav