import React from 'react'
import './New_header.css'

const New_header = () => {
    return (
        <header>
        <div className='container new_header__container'>
            <h5>Hello i'm</h5>
            <h1>Morris Simons</h1>
            <h5 className='text-light'>Student & Developer</h5>
            </div>
        </header>
    )
}

export default New_header