import React from 'react'
import './About.css'
import ME from '../../assets/me-about.jpg'
import about1 from '../../assets/bokaforarporvlogo4.png'
import about2 from '../../assets/about2.webp'
import about3 from '../../assets/about3.png'
const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      
      <div className="container about__container">

        <div className="about__content">
          <div className="about__cards">
            <div className="col-1"></div>
          <a href="https://bokaforarprov.se/">
            <article className="about__card">
              <img src={about1} alt="about1" className='about__card-image'/>
            </article>
          </a>
          <a href="https://www.trustpilot.com/review/passtider-snabbt.se?languages=all&sort=recency">
            <article className="about__card">
              <img src={about2} alt="about2" className='about__card-image'/>
            </article>
          </a>
          <a href="https://citas-rapidas.com.mx/">
            <article className="about__card">
              <img src={about3} alt="about3" className='about__card-image'/>
            </article>
          </a>
          </div>
          <div className='about__me__container'>

          <div className="about__me-image">
            <img src={ME} alt="AboutMeImage"/>
          </div>
          <div>
          <h4>MEng - AI and Machine Learning Student</h4>
          <p>
            
          I founded and built, together with one partner, Sweden's 3rd best e-commerce store according to Trustpilot out of 219 competing e-commerce stores on Trustpilot in only 6 months, with thousands of happy customers.
          <br></br>
          <br></br>
          Other projects I have built include Bokaförarprov.se and Citas-rapidas in Mexico, with over 300 bookings at the moment. I have also worked on various AI projects.
          </p>
          <a href="#contact" className="btn btn-primary talk-cta">Let's Talk</a>
          </div>
          </div>
          
        </div>
      </div>
    </section>
  )
}

export default About
